<template>
  <div>
    <el-card>
      <common-user-center-title :title="'推广信息'"></common-user-center-title>
      <div class="user-info-box">
        <div class="info-box">
          <div class="header">
            <img v-lazy="this.distributionUserInfo.image"/>
          </div>
          <div class="info">
            <div class="label">昵称：{{this.distributionUserInfo.nickname}}</div>
            <div class="label">等级：{{this.distributionUserInfo.levelName}}</div>
            <div class="label">小店：{{this.distributionUserInfo.store_name}}</div>
          </div>
        </div>
      </div>
      <div class="card-info">
        <div class="card-box">
          <div class="card-title">
            <i class="el-icon-money"></i> 我的收益
          </div>
          <div class="card-content d-f">
            <div class="content-info">
              {{this.distributionUserInfo.total_money}}
              <br/>
              累计收入
            </div>
            <div class="content-info">
              {{this.distributionUserInfo.cash_money}}
              <br/>
              可提现金额
            </div>
            <router-link to="/distribution/applyProfit">
              <el-button type="success">申请提现</el-button>
            </router-link>
          </div>
        </div>
        <div class="card-box">
          <div class="card-title">
            <i class="el-icon-user"></i> 我的推广
          </div>
          <div class="card-content d-f">
            <div class="content-info">
              {{this.distributionUserInfo.lowest_level_nums}}
              <br/>
              我的推广
            </div>
            <div class="content-info">
              {{this.distributionUserInfo.all_counts}}
              <br/>
              全部推广
            </div>
            <router-link to="/distribution/mySubordinate">
              <el-button type="success">查看下级</el-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-info">
        <div class="card-box" style="width: 65%">
          <div class="card-title">
            <i class="el-icon-document"></i> 收益列表
          </div>
          <div class="normal-card">
            <el-table :data="distributionProfitList">
              <el-table-column width="180" label="订单号" prop="no" />
              <el-table-column width="180" show-overflow-tooltip label="下单人" prop="name" />
              <el-table-column label="收益" width="50" prop="money" />
              <el-table-column width="160" label="下单时间" prop="created_at" />
            </el-table>
          </div>
        </div>
        <div class="card-box" style="width: 31%">
          <div class="card-title">
            <i class="el-icon-postcard"></i> 推广信息
          </div>
          <div class="normal-card">
            <img id="qrCodeImg" class="qr-code-img" v-lazy="myDistributionQrCode"/>
            <el-button type="primary" @click="saveMyDistributionQrCode">保存二维码</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import {
  distributionUserInfoUrl, getMyDistributionInfoUrl,
  getWechatDistributionCodeImgUrl,getDistributionProfitListUrl
} from '@/api/index'
import { downloadImg } from '@/utils/util.js'

export default {
  name: 'MyDistributionInfo',
  components: {
    commonUserCenterTitle
  },
  data () {
    return {
      distributionUserInfo: {},
      myDistributionInfo: {},
      myDistributionQrCode: '',
      queryInfo: {
        distributeUserId: '',
        pageSize: 5,
        page: 1,
        field: '',
        desc: '',
      },
      distributionProfitList:[]
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getMyDistributionInfo()
      this.getDistributionUserInfo()
      this.getMyDistributionQrCode()
      this.getDistributionProfitList()
    },
    // 获取我的推广信息
    async getMyDistributionInfo () {
      const { data: res } = await this.$http.get(getMyDistributionInfoUrl)
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.myDistributionInfo = res.data
    },
    // 获取我的推广二维码
    async getMyDistributionQrCode () {
      const { data: res } = await this.$http.get(getWechatDistributionCodeImgUrl)
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.myDistributionQrCode = res.data
    },
    // 获取推广中心首页
    async getDistributionUserInfo () {
      this.distributionUserInfo = JSON.parse(window.sessionStorage.getItem('distributionUserInfo'))
      if (this.distributionUserInfo == '' || this.distributionUserInfo == null || JSON.stringify(this.distributionUserInfo) == '{}') {
        const { data: res } = await this.$http.get(distributionUserInfoUrl)
        if (res.code !== 200) return this.$message.error(res.msg)
        window.sessionStorage.setItem('distributionUserInfo', JSON.stringify(res.data))
      }
      console.log(this.distributionUserInfo)
    },
    // 获取我的收益明细列表
    async getDistributionProfitList(){
      console.log(this.distributionUserInfo)
      this.queryInfo.distributeUserId = this.distributionUserInfo.id
      const{data:res} = await this.$http.get(getDistributionProfitListUrl,{params:this.queryInfo})
      if(res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.distributionProfitList = res.data.list
    },
    // 保存我的推广二维码
    saveMyDistributionQrCode () {
      downloadImg('#qrCodeImg', '我的小程序推广码')
    },
  }
}
</script>

<style lang="less" scoped>
  .user-info-box{
    display: flex;
    .info-box{
      display: flex;
      justify-content: start;
      .header{
        img{
          width: 100px;
          height: 100px;
        }
      }
      .info{
        margin: 4px 20px;
        font-size: 14px;
        color: #666666;
        line-height: 30px;
      }
    }
    .profit-box{
      margin-left: 20px;
    }
  }
  .card-info{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .card-box{
      width: 48%;
      border: 1px solid #dddddd;
      .card-title{
        font-size: 14px;
        padding-left: 20px;
        color: #333333;
        height: 40px;
        line-height: 40px;
        background-color: #f5f5f5;
        border-bottom: 1px solid #dddddd;
      }
      .card-content{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        .content-info{
          font-size: 14px;
          color: #666666;
          text-align: center;
        }

      }
      .normal-card{
        .qr-code-img {
          display: block;
          margin: 20px auto;
          width: 220px;
          height: 220px;
        }
        .el-button{
          display: block;
          margin: 0 auto 20px;
        }
      }
    }
  }

</style>
