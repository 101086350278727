import { render, staticRenderFns } from "./MyDistributionInfo.vue?vue&type=template&id=1a766287&scoped=true&"
import script from "./MyDistributionInfo.vue?vue&type=script&lang=js&"
export * from "./MyDistributionInfo.vue?vue&type=script&lang=js&"
import style0 from "./MyDistributionInfo.vue?vue&type=style&index=0&id=1a766287&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a766287",
  null
  
)

export default component.exports